import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import AppRoute from '../components/AppRoute';
import NotFound from '../components/NotFound';
import Redir from '../components/Redir';
import routes from "../routes";
import Layout from "./Layout";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useHistory,
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    "min-height": "100vh",
    "flex-direction": "column",
  },
}));
/*
display: 'flex',
  'min-height': '100vh',
  'flex-direction': 'column'
  */
function Home() {
  const classes = useStyles();
  //const storedJwt = localStorage.getItem('token');
  //const [jwt, setJwt] = useState(storedJwt || null);
  return (
    <div className={classes.root}>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/" component={Redir}/>
            {routes.map((route) => (
              <AppRoute
                path={route.path}
                key={route.path}
                component={route.component}
                isPrivate={route.isPrivate}
              />
            ))}
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </Router>
    </div>
  );
}

export default Home;

import React from "react";
import Home from "./pages/Home";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import theme from "./theme";
import { StoreProvider } from "./store";

function App() {
  return (
   
      <StoreProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Home />

        </ThemeProvider>
      </StoreProvider>
   
  );
}

export default App;

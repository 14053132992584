import { createMuiTheme } from '@material-ui/core/styles';
const theme = createMuiTheme({
  palette: {
    /*primary: { light: "#97F227", main: "#77E600", dark: "#5CBF00" }*/
    primary: { light: "#FBFFF2", main: "#77E600", dark: "#8bb917" }
    /*dummy:{ light: "#f6ffe2", main: "#86b325", dark: "#537608" }*/
  },
  overrides: {
    MuiTableRow: {
      root:
      {
        '&:hover': {
          cursor: 'pointer'
        }
      }
    }
  }
});
export default theme;
import React from "react";

import { Paper, Button, Hidden } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useForm, Controller } from 'react-hook-form';

import { useState } from 'react';
import { useStore } from '../store';
import http from '../http';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
      </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flex: "1 0 auto",

    },
    '@global': {
        '.MuiCheckbox-root': {
            color: theme.palette.primary.main
        }
    },
    paper: {

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: theme.spacing(4),
        padding: theme.spacing(4),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
    },
    ginput: {
        border: "1px solid #e2e2e1",
        overflow: "hidden",
        borderRadius: 4,
        backgroundColor: theme.palette.grey[200],
    },
    submit: { width: "100%", color: "#fff" },
    checkbox: {
        width: "100%",

        color: theme.palette.primary.main,
        
        margin: 0,
    },

    margin0: { marginTop: 16, marginBottom: 16 }
}));

function SignIn(props) {
    const classes = useStyles();
    //const history=useHistory();
    /*
    const handleSubmit = (event) => {
        //Make a network call somewhere
        event.preventDefault();
    }
    */
    //const history = useHistory();
    const { state, dispatch } = useStore();
    const [loginError, setLoginError] = useState(false);
    const { register, handleSubmit, watch, errors, control } = useForm();
    const onSubmit = (data) => {
        console.log(JSON.stringify(data, null, 4));
        http.post('/auth/login', data)
            .then(res => {
                console.log('res:', res.data);
                //let state0 = {token:res.data.accessToken,user:res.data.payload,message:res.data.message,lang:state.lang}
                //localStorage.setItem('info', JSON.stringify(state0, null, 4));

                dispatch({ type: 'LOGIN', payload: res.data })
                console.log('state:', state);
                //setJwt(data.accessToken);
                props.history.push('/exams');
            }
            )
            .catch(error => { console.log(error); setLoginError(true); })
            .finally(() => { console.log('state:', state); })
        /* for test
        if (data.email === 'error') {
            setLoginError(true);
            console.log('error');
            //history.push("/");
        }
        else {
            //history.push("/signup");
            console.log('login ok');
        }
        */
    }
    //console.log(watch("email"));
    return (
        <div className={classes.root}>
            <Container component="main" maxWidth="sm">
                <Paper className={classes.paper}>

                    <Typography component="h1" variant="h4">
                        {state.dict.user.signin.title}
                        {/*JSON.stringify(state,null,4)*/}
                    </Typography>
                    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            onFocus={() => { console.log('errors',errors);setLoginError(false) }}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={state.dict.user.signin.email}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            inputRef={register({ required: true })}
                        />
                        {errors.email && <Typography color="error">
                            {state.dict.user.signup.required}
                        </Typography>}
                        <TextField
                            onFocus={() => { setLoginError(false) }}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={state.dict.user.signin.password}
                            type="password"
                            id="password"
                            inputRef={register({ required: true })}
                            autoComplete="current-password"
                        />
                        {errors.password && <Typography color="error">
                            {state.dict.user.signup.required}
                        </Typography>}
                        <div className={classes.margin0}>
                            <Grid
                                container
                                spacing={2}

                            >
                                <Hidden xsDown>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            className={classes.checkbox}
                                            control={<Checkbox name="remember" color="primary" inputRef={register} />}
                                            label={state.dict.user.signin.remember}
                                        />
                                    </Grid>
                                </Hidden>
                                {/*
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        className={classes.checkbox}
                                        control={
                                            <Controller as={Checkbox} control={control} name="remember" color="primary"/>
                                        }
                                        label="Remember me"
                                    />
                                </Grid>
                                */}
                                <Grid item xs={12} sm={6}>
                                    <Button color="primary"
                                        type="submit"
                                        variant="contained"
                                        className={classes.submit}
                                    >
                                       {state.dict.user.signin.title}
                                    </Button>
                                    {/*
                                    {loginError && (
                                        <Redirect to={'/signup'} />
                                    )}
                                    */}

                                </Grid>
                                <Hidden smUp>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                            className={classes.checkbox}
                                            control={<Checkbox name="remember" color="primary" inputRef={register} />}
                                            label={state.dict.user.signin.remember}
                                        />
                                    </Grid>
                                </Hidden>
                                <Grid item xs={12}>

                                    {loginError && (
                                        <Typography color="error" onClick={() => { setLoginError(false) }}>
                                            {state.dict.user.signup.tryagain}
                                        </Typography>
                                    )}

                                </Grid>
                            </Grid>
                        </div>
                        <Grid container direction="column" spacing={1}
                            alignItems="center"
                            justify="center">
                            <Grid item xs={12}>
                                <Typography variant="body2" color="primary"><Link href="#">
                                {state.dict.user.signin.forget}
                                    </Link></Typography>
                            </Grid>
                            {/*
                            <Grid item xs={12}>
                                <Typography variant="body2" color="primary">
                                {state.dict.user.signin.noaccount}<Link href="/signup" >{state.dict.user.signin.signup}</Link>
                            </Typography>
                            
                            </Grid>
                            */}
                        </Grid>
                    </form>

                </Paper>
            </Container>
        </div>
    );
}
export default SignIn;

import React from "react";
import { useStore } from '../store';
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Container,
  Paper,
  
  Grid,

} from "@material-ui/core";

import { Link } from "react-router-dom";
var parse = require('html-react-parser');
const useStyles = makeStyles((theme) => ({
  root: {
    flex: "1 0 auto",
  },
  paper: {
    margin: 20,
    padding: 20,
  },
  typo: {
    padding: 10,
    margin: 10,
  },
  logo: {
    maxWidth: 372,
  },
}));
function ConfirmOK(props) {
  const classes = useStyles();
  const { state, dispatch } = useStore();
  //console.log("not found");
  //const location = useLocation();
  //console.log(location.pathname);
  //console.log(watch("email"));
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Grid container justify="center" spacing={0}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="h3">{state.dict.user.signup.confirmok}</Typography>
              <Typography className={classes.typo}>
                {/*
              <div dangerouslySetInnerHTML={{__html: state.dict.user.signup.confirmokmsg}} />
                */}
                {parse(state.dict.user.signup.confirmokmsg)}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default ConfirmOK;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Container,
  Paper,
  
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import { Icon } from "@iconify/react";
import roadVariant from "@iconify/icons-mdi/road-variant";
import recycleIcon from "@iconify/icons-mdi/recycle";
//<Icon icon={roadVariant} />
//<Icon icon={recycleIcon} />
const useStyles = makeStyles((theme) => ({
  root: {
    flex: "1 0 auto",
  },
  paper: {
    margin: 20,
    padding: 20,
  },
  typo: {
    padding: 10,
    margin: 10,
  },
}));
function AboutUs_en() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Paper className={classes.paper}>
          <Typography variant="h3" className={classes.typo}>
            LELTEK INC.
          </Typography>
          <Typography className={classes.typo}>
            Leltek was founded in 2013 and headquartered in Taiwan, has a
            professional R&D team, mainly develops ultrasound imaging system.
            <p />
            Under the leadership of our general manager, we take the mission of
            improving the quality of human life, innovate the technology to
            provide more user-friendly medical products and services.
            <p />
            Currently, we specialize in ultrasound medical imaging system
            miniaturization, hoping to provide more medical resources to remote
            areas and the Third World rural areas.
          </Typography>
          <Typography variant="h3" className={classes.typo}>
            Our Vision
          </Typography>
          <Typography className={classes.typo}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Card>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography>
                      <AccessTimeIcon style={{ fontSize: 40 }} />
                    </Typography>
                    <Typography variant="h6">Honest</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography>
                      <DirectionsRunIcon style={{ fontSize: 40 }} />
                    </Typography>
                    <Typography variant="h6">Active</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography>
                      <Icon icon={roadVariant} style={{ fontSize: 40 }} />
                    </Typography>
                    <Typography variant="h6">Steady</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography>
                      <Icon icon={recycleIcon} style={{ fontSize: 40 }} />
                    </Typography>
                    <Typography variant="h6">Sustainability</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Typography>
          <Typography variant="h3" className={classes.typo}>
            LeSONO
          </Typography>

          <Typography className={classes.typo}>
            In 2013, General Manager, Mr. Liu, with the start-up team dedicated
            to developing medical devices. With innovative product concepts,
            professional research development team members and our patents and
            technologies, we established our own company “LeSONO”.
            <p />
            We believe that the concept of preventive medicine and home care
            will gradually become more popular in the future. The best
            implementation of this concept of medical products is miniaturized
            portable medical equipment.
            <p />
            Leltek Inc. upholds the mission to enhance the quality of human
            life. Director Miss Chang had said: “Hopefully, in the future, it is
            our product saving people in the emergency room.”. We try to develop
            products that will improve the medical care.
            <p />
            Ultrasound miniaturization technology is expected to give access to
            ultrasound devices to clinics, ambulances, home care, emergency
            room, as well as provide a better quality of medical care in remote
            areas or the third world.
          </Typography>
          <Typography variant="h3" className={classes.typo}>
            Certifications
          </Typography>
          <Typography className={classes.typo}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Card>
                  <CardContent style={{ textAlign: "center" }}>
                    <img src="/ISO13485-212x300.jpg" />
                    <Typography variant="h6">ISO13485</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card>
                  <CardContent style={{ textAlign: "center" }}>
                    <img src="TFDA-212x300.jpg" />
                    <Typography variant="h6">TFDA</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card>
                  <CardContent style={{ textAlign: "center" }}>
                    <img src="FDA-232x300.jpg" />
                    <Typography variant="h6">FDA</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Typography>
        </Paper>
      </Container>
    </div>
  );
}

export default AboutUs_en;

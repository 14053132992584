import * as React from "react";
import Avatar from "@material-ui/core/Avatar";
import { Button, Paper } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import http from '../http';
import { useState } from 'react';
import { useStore } from '../store';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

const validationSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required().email('Email is invalid'),
    password: yup.string().required()
    .min(6, 'Password must be at least 6 characters')
    .max(32, 'Password must be at most 32 characters')
    .matches(/[A-Z]/, 'Password must contains uppercase characters')
    .matches(/[a-z]/, 'Password must contains lowercase characters')
    .matches(/[0-9]/, 'Password must contains numbers'),
    confirmpassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    agreeterms: yup.boolean()
        .required("The terms and conditions must be accepted.")
        .oneOf([true], "The terms and conditions must be accepted.")
});

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://material-ui.com/">
                Your Website
      </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        flex: "1 0 auto",

    },

    paper: {

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: theme.spacing(4),
        padding: theme.spacing(4),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: 8,
        marginBottom: 16
    },
    ginput: {
        border: "1px solid #e2e2e1",
        overflow: "hidden",
        borderRadius: 4,
        backgroundColor: theme.palette.grey[200],
    },
    submit: { width: "100%", color: "#fff" },
    checkbox: {


        color: theme.palette.text.secondary,

    },
    margin0: { marginTop: 16, marginBottom: 16 }
}));

function SignUp(props) {
    
    const classes = useStyles();
    // submit here
    //const history = useHistory();
    const { state, dispatch } = useStore();
    const [loginError, setLoginError] = useState(false);
    const { register, handleSubmit, reset, errors } = useForm({
        resolver: yupResolver(validationSchema)
    });
    const onSubmit = (data) => {
        data.role = "individualGuest";
        console.log(JSON.stringify(data, null, 4));
        http.post('/user/register', data)
            .then(res => {
                console.log('res:', res.data);
                //localStorage.setItem('info', JSON.stringify(res.data, null, 4));

                //dispatch({ type: 'LOGIN', payload: res.data })
                //console.log('state:', state);
                //setJwt(data.accessToken);
                props.history.push('/confirm');
            }
            )
            .catch(error => { console.log(error); setLoginError(true); })
            .finally(() => { console.log('state:', state); })
        /* for test
        if (data.email === 'error') {
            setLoginError(true);
            console.log('error');
            //history.push("/");
        }
        else {
            //history.push("/signup");
            console.log('login ok');
        }
        */
    }
    return (
        <div className={classes.root}>
            <Container component="main" maxWidth="sm">
                <Paper className={classes.paper}>

                    <Typography component="h1" variant="h4">
                        {state.dict.user.signup.title}
                </Typography>
                    <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)} onReset={reset}>
                        <Grid container spacing={2} alignItems="center"
                            justify="center">
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    onFocus={() => { setLoginError(false) }}
                                    autoComplete="fname"
                                    name="firstName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    margin="dense"
                                    id="firstName"
                                    label={state.dict.user.signup.fname}
                                    autoFocus
                                    inputRef={register}
                                />
                                {errors.firstName && <Typography color="error">
                                    {state.dict.user.signup.required}</Typography>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    onFocus={() => { setLoginError(false) }}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    margin="dense"
                                    id="lastName"
                                    label={state.dict.user.signup.lname}
                                    name="lastName"
                                    autoComplete="lname"
                                    inputRef={register}
                                />
                                {errors.lastName && <Typography color="error">
                                    {state.dict.user.signup.required}</Typography>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    onFocus={() => { setLoginError(false) }}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    margin="dense"
                                    id="email"
                                    label={state.dict.user.signup.email}
                                    name="email"
                                    autoComplete="email"
                                    inputRef={register}
                                />
                                {errors.email && <Typography color="error">
                                    {errors.email?.message}</Typography>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    onFocus={() => { setLoginError(false) }}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    margin="dense"
                                    name="password"
                                    label={state.dict.user.signup.password}
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    inputRef={register}
                                />
                                <Typography>{state.dict.user.signup.passwordrule}</Typography>
                                {errors.password && <Typography color="error">
                                    {errors.password?.message}</Typography>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    onFocus={() => { setLoginError(false) }}
                                    variant="outlined"
                                    required
                                    fullWidth
                                    margin="dense"
                                    name="confirmpassword"
                                    label={state.dict.user.signup.password2}
                                    type="password"
                                    id="confirmpassword"
                                    autoComplete="current-password"
                                    inputRef={register}
                                />
                                {errors.confirmpassword && <Typography color="error">
                                    {errors.confirmpassword?.message}</Typography>}
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel style={{ display: 'table' }} className={classes.checkbox}
                                    control={<div style={{ display: 'table-cell' }}><Checkbox id="subscription" name="subscription" color="primary" inputRef={register} /></div>}
                                    label={state.dict.user.signup.subscription}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel style={{ display: 'table' }} className={classes.checkbox}
                                    control={<div style={{ display: 'table-cell' }}><Checkbox id="agreeterms" name="agreeterms" color="primary" inputRef={register} /></div>}
                                    label={state.dict.user.signup.agree}
                                />
                                {errors.agreeterms && <Typography color="error">
                                    {errors.agreeterms?.message}</Typography>}
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    {state.dict.user.signup.submit}
                                    </Button>
                            </Grid>
                            <Grid item xs={12}>

                                {loginError && (
                                    <Typography color="error" onClick={() => { setLoginError(false) }}>
                                        {state.dict.user.signup.tryagain}
                                    </Typography>
                                )}

                            </Grid>
                            <Grid item xs={12} container alignItems="center"
                                justify="center">

                                <Typography variant="body2" color="primary">
                                    {state.dict.user.signup.hasaccount} <Link href="/signin">{state.dict.user.signup.signin}</Link>
                                    </Typography>
                            </Grid>
                        </Grid>

                    </form>

                </Paper>
            </Container>
        </div>
    );
}
export default SignUp;

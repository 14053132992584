import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Container, Paper, Grid } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  root: {
    flex: "1 0 auto",
  },
  paper: {
    margin: 20,
    padding: 20,
  },
  typo: {
    padding: 10,
    margin: 10,
  },
  logo: {
    maxWidth: 372
  }
}));
function ErrorFound() {
  const classes = useStyles();
  console.log('not found');
  //const location = useLocation();
  //console.log(location.pathname);
  //console.log(watch("email"));
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Grid container justify="center" spacing={0}>

          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="h3">
                Some error occurred, please try again later.
                </Typography>

            </Paper>
          </Grid>

        </Grid>
      </Container>
    </div>
  );
}

export default ErrorFound;

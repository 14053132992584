import React from "react";
import {
    AppBar,
    Divider,
    IconButton,
    MenuItem,
    Menu,
    Typography,
    Toolbar,
    Button,
    Collapse
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { useStore } from "../store";
import {
    Drawer,
    Grid,
    List,
    ListItem,
    ListItemText,
    Container,
} from "@material-ui/core";
import { AccountCircle, ExpandLess, ExpandMore } from "@material-ui/icons";
//import HomeIcon from "@material-ui/icons/Home";
import { Link } from "react-router-dom";
//import Link from '@material-ui/core/Link';
import { languageOptions,languageMenu } from "../languages";

const useStyles = makeStyles((theme) => ({
    root: {},
    toolbar: {
        width: "100%",
        "padding-left": 0,
        "padding-right": 0,
    },
    appBar: {
        background: theme.palette.primary.light,
        "box-shadow": "none",
        "border-bottom-color": theme.palette.primary.main,
        "border-bottom-width": 1,
        "border-bottom-style": "solid",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    drawer: {
        width: 180,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 180,
        backgroundImage: `linear-gradient(#cfd9df,#e2ebf0)`,
        color: "grey",
    },
    bigAvatar: {
        margin: 30,
        width: 100,
        height: 100,
    },
    link: {
        textDecoration: "none",
        color: theme.palette.text.primary,
    },
    rightMenu: {
        marginLeft: "auto",
        marginRight: -12,
    },
    logo: {
        maxHeight: 50,
        [theme.breakpoints.down("sm")]: {
            maxHeight: 40,
        },
    },
    toolbarButtons: {
        marginLeft: "auto",
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
    linkcolor: {
        color: theme.palette.primary.main,
        textDecoration: "none",
    },
    menuMobile: {
        display: "none",
        [theme.breakpoints.down("sm")]: {
            display: "block",
        },
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

function HeaderRWD() {
    //const ref=React.createRef();
    const { state, dispatch } = useStore();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [subopen, setSubopen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMobile, setOpenMobile] = useState(false);
    //const [anchorElMobile, setAnchorElMobile] = useState(null);
    const handleSubClick = () => {
        setSubopen(!subopen);
    };
    const toggleDrawer = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        if (open) {
            setAnchorEl(event.currentTarget);
        } else {
            setAnchorEl(null);
        }
        setOpen(open);
    };
    const toggleDrawerMobile = (open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        /*
            if (open) {
                setAnchorEl(event.currentTarget);
            }
            else {
                setAnchorEl(null);
            }
            */
        setOpenMobile(open);
    };
    const handleLangChangeMobile = (event) => {
        console.log('opt', event.currentTarget.tagName);
        //setLang(event.target.value);
        //localStorage.setItem('lang', event.target.value);
        //console.log('new',lang);
        dispatch({ type: 'CHANGE_LANG', lang: event.currentTarget.attributes['value'].value });
        setOpenMobile(false);
        setOpen(false);
        setSubopen(false);
    };

    const DrawMenu = (
        <Menu
            getContentAnchorEl={null}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={open}
            onClose={toggleDrawer(false)}
        >
            <MenuItem component={Link} to="/profile" onClick={toggleDrawer(false)}>
                {state.dict.menu.desktop.profile}
            </MenuItem>

            <MenuItem component={Link} to={state.user.email ? "/signout" : "/signin"} onClick={toggleDrawer(false)}>
                {state.user.email ? state.dict.menu.desktop.logout : state.dict.menu.desktop.login}
            </MenuItem>
            <Divider />
            <MenuItem button onClick={handleSubClick}>
                <ListItemText primary={state.dict.menu.desktop.lang} />
                {subopen ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>
            <Collapse in={subopen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {Object.entries(languageMenu).map(([id, name]) => (
                        <ListItem
                            button
                            className={classes.nested}
                            key={id} value={id} onClick={handleLangChangeMobile}
                        >
                            <ListItemText primary={state.dict.menu.desktop[id]} />

                        </ListItem>

                    ))}
                </List>
            </Collapse>
        </Menu>
    );
    const DrawerMobile = (
        <Drawer
            variant="temporary"
            anchor="left"
            open={openMobile}
            onClose={toggleDrawerMobile(false)}
        >
            <Grid container justify="center" alignItems="center">
                <IconButton color="inherit">
                    <AccountCircle style={{ fontSize: "50px" }} />
                </IconButton>
            </Grid>
            <List>
                {state.user.email ? <div></div> :
                    <ListItem
                        button
                        key="signup"
                        component={Link}
                        to="/signup"
                        onClick={toggleDrawerMobile(false)}
                    >
                        <ListItemText>
                            <Typography>{state.dict.menu.mobile.signup}</Typography>
                        </ListItemText>
                    </ListItem>
                }
                <ListItem
                    button
                    key="signin"
                    component={Link}
                    to={state.user.email ? "/signout" : "/signin"}
                    onClick={toggleDrawerMobile(false)}
                >
                    <ListItemText>
                        <Typography> {state.user.email ?
                            state.dict.menu.mobile.logout :
                            state.dict.menu.mobile.login}</Typography>
                    </ListItemText>
                </ListItem>

                <ListItem
                    button
                    key="profile"
                    component={Link}
                    to="/profile"
                    onClick={toggleDrawerMobile(false)}
                >
                    <ListItemText>
                        <Typography>{state.dict.menu.mobile.profile}</Typography>
                    </ListItemText>
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem
                    button
                    key="exams"
                    component={Link}
                    to="/exams"
                    onClick={toggleDrawerMobile(false)}
                >
                    <ListItemText>
                        <Typography>{state.dict.header.exams}</Typography>
                    </ListItemText>
                </ListItem>
                <ListItem
                    button
                    key="institution"
                    component={Link}
                    to="/institution"
                    onClick={toggleDrawerMobile(false)}
                >
                    <ListItemText>
                        <Typography>{state.dict.header.institution}</Typography>
                    </ListItemText>
                </ListItem>

            </List>
            <Divider />
            <List>
                <ListItem button onClick={handleSubClick}>
                    <ListItemText primary={state.dict.menu.mobile.lang} />
                    {subopen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={subopen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {Object.entries(languageOptions).map(([id, name]) => (
                            <ListItem
                                button
                                className={classes.nested}
                                key={id} value={id} onClick={handleLangChangeMobile}
                            >
                                <ListItemText primary={state.dict.menu.mobile[id]} />

                            </ListItem>

                        ))}
                    </List>
                </Collapse>
            </List>
        </Drawer>
    );
    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar}>
                <Container maxWidth="md">
                    <Toolbar className={classes.toolbar}>
                        <div className={classes.menuMobile}>
                            <IconButton onClick={toggleDrawerMobile(true)}>
                                <MenuIcon />
                            </IconButton>
                        </div>
                        <Link to="/">
                            <img alt="LOGO" src="/LeltekCloud_210105.png" className={classes.logo} />
                        </Link>
                        <div className={classes.toolbarButtons}>
                            <Button color="primary">
                                <Link className={classes.linkcolor} to="/institution">
                                    {state.dict.header.institution}
                                </Link>
                            </Button>

                            <Button color="primary">
                                <Link className={classes.linkcolor} to="/exams">
                                    {state.dict.header.exams}{" "}
                                </Link>
                            </Button>
                            <IconButton
                                edge="end"
                                aria-haspopup="true"
                                color="inherit"
                                aria-label="menu"
                                onClick={toggleDrawer(true)}
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                color="inherit"
                            >
                                <AccountCircle style={{ fontSize: "50px" }} />
                            </IconButton>
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
            {DrawMenu}
            {DrawerMobile}
        </div>
    );
}

export default HeaderRWD;

import React from "react";
import HeaderRWD from "../components/Header-RWD2";
import FooterRWD from "../components/Footer-RWD2";
import { makeStyles } from "@material-ui/core/styles";
//import {userStore} from '../store';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    
    "min-height": "100vh",
    "min-height":"-webkit-fill-available",
    "flex-direction": "column",
  },
}));
function Layout(props) {
  const classes = useStyles();
  //const {state,dispatch}=useStore();
  return (
    <div className={classes.root}>
      <HeaderRWD />
      {props.children}
      <FooterRWD />
    </div>
  );
}

export default Layout;

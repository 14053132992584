import axios from 'axios';
const config = require('./config');

const http = axios.create({
    baseURL:config.baseURL,
    headers: {
        "Content-type": "application/json"
      },
       withCredentials: true 
    
});
/*
http.interceptors.request.use(
    config => {
      const { origin } = new URL(config.url);
      const allowedOrigins = [config.baseURL];
      const token = localStorage.getItem('token');
      if (allowedOrigins.includes(origin)) {
        //config.headers.authorization = `Bearer ${token}`;
        config.headers['x-access-token']=`${token}`
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
*/
export default http;
import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Paper, Typography, Grid, Card, CardContent } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import http from '../http';
import { useStore } from '../store';

import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: "1 0 auto",

    },
    paper: {
        display: "flex"
    },
    typo: {
        padding: 10,
        margin: 10,
    },
    card0: {
        display: "flex",

        justifyContent: "space-between",

        flexDirection: "column",
        minWidth: "100%",
        marginTop: 12,
        marginBottom: 12,
        padding: 12,
    }
}));


function SignOut(props) {
    const classes = useStyles();
    const { state, dispatch } = useStore();

    useEffect(() => {
        console.log('signout', state);
        let headers = { 'headers': { 'x-access-token': state.token } }
        http.post('/auth/logout', {}, headers)
            .then(res => {
                console.log('res:', res.data);
                //let state0 = {token:res.data.accessToken,user:res.data.payload,message:res.data.message,lang:state.lang}
                //localStorage.setItem('info', JSON.stringify(state0, null, 4));

                dispatch({ type: 'LOGOUT', payload: {} });
                console.log('state:', state);
                //setJwt(data.accessToken);

            }
            )
            .catch(error => { console.log(error); props.history.push('/'); })
            .finally(() => { console.log('state:', state); props.history.push('/'); })


    }, []);


    return (
        <div className={classes.root}>

        </div>
    );
}

export default SignOut;

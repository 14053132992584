import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Container,
  Paper,
  Link,
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";

import HomeIcon from '@material-ui/icons/Home';
import CallIcon from '@material-ui/icons/Call';
import MailIcon from '@material-ui/icons/Mail';
//<Icon icon={roadVariant} />
//<Icon icon={recycleIcon} />
const useStyles = makeStyles((theme) => ({
  root: {
    flex: "1 0 auto",
  },
  paper: {
    margin: 20,
    padding: 20,
    
  },
  typo: {
    padding: 10,
    margin: 10,
  },
  paper0:{
    display:"flex"
  },
  card0: {
    display: "flex",

    justifyContent: "space-between",

    flexDirection: "column",
    minWidth:"100%",
    marginTop: 8,
    marginBottom: 8,
    padding: 8,
}
}));
const iframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3616.957893548757!2d121.53896951486325!3d24.967547184003504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3468018b37902be7%3A0xa8db933abfb8b89a!2sNo.%20293%2C%20Section%201%2C%20Beixin%20Road%2C%20Xindian%20District%2C%20New%20Taipei%20City%2C%20231!5e0!3m2!1sen!2stw!4v1578898598325!5m2!1sen!2stw" height="450" width="100%" frameborder="0" style="border:0;" allowfullscreen=""></iframe>	';
function Iframe(props) {
  return (<div style={{ minWidth: '100%' }} dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }} />);
}
function ContactUs_en() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Paper className={classes.paper}>
          <Typography variant="h3" className={classes.typo}>
            Contact Us
          </Typography>

          <Typography className={classes.typo}>
            <Grid container spacing={1} direction="row"
              justify="center"
              alignItems="stretch">
              <Grid item xs={4} className={classes.paper0}>
                <Card className={classes.card0}>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography>
                      <HomeIcon color="primary" style={{ fontSize: 80 }} />
                    </Typography>
                    <Typography variant="h5">VISIT US</Typography>
                    <Typography style={{ fontSize: 14 }} className={classes.typo}>
                      6F-3, No.293, Sec.1, Beixin Rd., Xindian Dist., New Taipei City, Taiwan (R.O.C)
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4} className={classes.paper0}>
                <Card className={classes.card0}>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography>
                      <CallIcon color="primary" style={{ fontSize: 80 }} />
                    </Typography>
                    <Typography variant="h5">CALL US</Typography>
                    <Typography style={{ fontSize: 14 }} className={classes.typo}>
                      Phone: +886-2-2913-7577<br />
FAX: +886-2-2913-7599
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4} className={classes.paper0}>
                <Card className={classes.card0}>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography>
                      <MailIcon color="primary" style={{ fontSize: 80 }} />
                    </Typography>
                    <Typography variant="h5">CONTACT US</Typography>
                    <Typography style={{ fontSize: 14 }} className={classes.typo}>
                      info@leltek.com
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              
              <Grid item xs={12} style={{ display: 'flex' }} />
              <Grid item xs={12} style={{ display: 'flex' }}>
                <Iframe iframe={iframe} />
              </Grid>
            </Grid>
          </Typography>

        </Paper>
      </Container>
    </div>
  );
}

export default ContactUs_en;

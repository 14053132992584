import React from "react";
import { useState } from 'react';
import { useStore } from '../store';
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Container,
  Paper,

  Grid,
  Card,
  CardContent,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import { Icon } from "@iconify/react";
import roadVariant from "@iconify/icons-mdi/road-variant";
import recycleIcon from "@iconify/icons-mdi/recycle";
import AboutUs_en from './AboutUs-en';
import AboutUs_zh_TW from './AboutUs-zh_TW';

//<Icon icon={roadVariant} />
//<Icon icon={recycleIcon} />
const useStyles = makeStyles((theme) => ({
  root: {
    flex: "1 0 auto",
  },
  paper: {
    margin: 20,
    padding: 20,
  },
  typo: {
    padding: 10,
    margin: 10,
  },
}));
function AboutUs() {
  const classes = useStyles();
  const { state, dispatch } = useStore();
  let lang = (state.lang) ? state.lang : 'en';
  //const ContactComp=`ContactUs${lang}`;
  //return <ContactComp/>;
  switch (lang) {
    case 'en':
      return <AboutUs_en />;
    case 'zh_TW':
      return <AboutUs_zh_TW />;
    default:
      return <AboutUs_en />;

  }
  
}

export default AboutUs;

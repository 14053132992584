import React from "react";
import { makeStyles } from "@material-ui/core/styles";


import {  Redirect } from "react-router-dom";

import { useStore } from "../store";
import jwt_decode from "jwt-decode";
const useStyles = makeStyles((theme) => ({
    root: {
        flex: "1 0 auto",
    },
    paper: {
        margin: 20,
        padding: 20,
    },
    typo: {
        padding: 10,
        margin: 10,
    },
    logo: {
        maxWidth: 372,
    },
}));


const verifyJWT = (token) => {
    if (token === "") return false;
    var isExpired = false;
    var decodedToken = jwt_decode(token);
    var dateNow = new Date();
    let ts = Math.floor(dateNow / 1000);
    console.log("jwt", JSON.stringify(decodedToken, null, 4));
    console.log("now", ts);
    if (decodedToken.exp < ts) isExpired = true;
    return !isExpired;
};

function Redir() {
    const { state, dispatch } = useStore();
    console.log('in redir',state);
    if (state && state.token && verifyJWT(state.token)) {
        
        return <Redirect to={{ pathname: "/exams" }} />;
    } else {
        return <Redirect to={{ pathname: "/signin" }} />;
    }

}

export default Redir;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStore } from '../store';

import { useParams } from "react-router-dom";
import { useEffect } from "react";
import http from "../http";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: "1 0 auto",
    },
    paper: {
        margin: 20,
        padding: 20,
    },
    typo: {
        padding: 10,
        margin: 10,
    },
    logo: {
        maxWidth: 372,
    },
}));
function VerifyEmail(props) {
    const classes = useStyles();
    const { state, dispatch } = useStore();
    //const location = useLocation();
    //console.log(location.pathname);
    //console.log(watch("email"));
    let { URL } = useParams();
    useEffect(() =>{
        console.log('verify');
        http.get('/auth/email-verification/'+ URL )
        .then((res) => { 
            // add cookie/localstorage
            dispatch({ type: 'LOGIN', payload: res.data })
            props.history.push('/confirmed');
        })
        .catch((error) => {
            props.history.push('/error');
        })
        .finally(() => { })
      },[]);
    

    return (
        <div className={classes.root}>
            
        </div>
    );
}

export default VerifyEmail;


import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import AboutUs from './components/AboutUs';
import Terms from './components/Terms';
import ContactUs from './components/ContactUs';

import Confirm from './components/Confirm';
import ConfirmOK from './components/ConfirmOK';
import VerifyEmail from './components/VerifyEmail';
import Exams from './components/Exams';
import SingleExam from './components/SingleExam3';
import SignOut from './components/SignOut';

import ErrorFound from './components/ErrorFound';
const routes=[
    {
        path:'/exams',
        component:Exams,
        isPrivate:true
    },
    {
        path:'/single/:ID',
        component:SingleExam,
        isPrivate:true
    },
    {
        path:'/signin',
        component:SignIn,
        isPrivate:false
    },
    {
        path:'/signout',
        component:SignOut,
        isPrivate:true
    },
    {
        path:'/signup',
        component:SignUp,
        isPrivate:false
    },
    {
        path:'/about',
        component:AboutUs,
        isPrivate:false
    },
    {
        path:'/contact',
        component:ContactUs,
        isPrivate:false
    },
    {
        path:'/privacy',
        component:Terms,
        isPrivate:false
    },

    {
        path:'/confirm',
        component:Confirm,
        isPrivate:false
    },
    {
        path:'/confirmed',
        component:ConfirmOK,
        isPrivate:false
    },
    {
        path:'/verifyemail/:URL',
        component:VerifyEmail,
        isPrivate:false
    },
    {
        path:'/error',
        component:ErrorFound,
        isPrivate:false
    }
]
export default routes;
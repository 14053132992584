import en from './en.json';
import zh_TW from './zh_TW.json';
let en_US=en;
let zh_tw = zh_TW;
export const dictionaryList={en,en_US,zh_TW,zh_tw}
export const languageOptions={
    en:'English',
    en_US:'English',
    zh_TW:'Traditional Chinese',
    zh_tw:'Traditional Chinese'
}
export const languageMenu={
    en:'English',
    
    zh_TW:'Traditional Chinese'
}
import React from "react";
import { useContext, createContext, useReducer, useEffect } from "react";
import { languageOptions, dictionaryList } from './languages';

const StoreContext = createContext({ lang: 'zh_TW', dict: dictionaryList.zh_TW });
//const LangContext = createContext({ lang: 'zh_TW', dict: dictionaryList.zh_TW });
/*
export function LangProvider({ children }) {
    const [userLang, setUserLang] = useState('zh_TW');
    const provider = {
        userLang, dict: dictionaryList[userLang], usrLangChange: selected => {
            const newLang = languageOptions[selected] ? selected : 'zh_TW';
            setUserLang(newLang);
            localStorage.setItem('lang', newLang)
        }
    }
    return (
        <LangContext.Provider value={provider}>
            {children}
        </LangContext.Provider>
    )
}*/
console.log('local store', JSON.parse(localStorage.getItem("info")))
let user = localStorage.getItem("info")
    ? JSON.parse(localStorage.getItem("info")).user
        ? JSON.parse(localStorage.getItem("info")).user : ""
    : "";
let token = localStorage.getItem("info")
    ? JSON.parse(localStorage.getItem("info")).token
        ? JSON.parse(localStorage.getItem("info")).token : ""
    : "";
let lang = localStorage.getItem("info") ? JSON.parse(localStorage.getItem("info")).lang ?
    JSON.parse(localStorage.getItem("info")).lang :
    navigator.languages[0].replace('-', '_') : navigator.languages[0].replace('-', '_');
//lang = 'en_US';
if (!(lang in dictionaryList)) {
    console.log('no match lang');
    lang = 'en';
}
console.log('from local store', user, token, lang)
const initState = { user: "" || user, token: "" || token, message: "", lang: lang, dict: dictionaryList[lang] };
console.log('init state:', initState);

const reducer = (state, action) => {
    console.log("in reducer:", action);
    switch (action.type) {
        case "LOGIN":
            console.log("login ok");
            return {
                ...state,
                user: action.payload.payload,
                token: action.payload.accessToken,
            };
        case "LOGOUT":
            console.log("logout");
            return {
                ...state,
                user: {},
                token: "",
            };
        case "CHANGE_LANG":
            console.log("change lang", action);
            return {
                ...state, lang: action.lang, dict: dictionaryList[action.lang]
            }
        case "ERROR":
            console.log("error");
            return {
                ...state,
                message: action.error,
            };
        default:
            throw new Error("wrong aciton");
    }
};

export const StoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initState);

    useEffect(() => {
        // ??? NOT SURE
        /*
        let temp = { ...state }
        delete temp.dict
        localStorage.setItem("info", JSON.stringify(temp, null, 4));
        console.log('effect', JSON.stringify(state, null, 4), JSON.stringify(temp, null, 4))
        //localStorage.setItem("lang",state.lang);
        */
        localStorage.setItem("info", JSON.stringify(state, null, 4));

        console.log('in effect');
    }, [state]);

    return (
        <>

            <StoreContext.Provider value={{ state, dispatch }}>
                {console.log('render state', JSON.stringify(state, null, 4))}
                {children}
            </StoreContext.Provider>
        </>
    );
};


export const useStore = () => useContext(StoreContext);

import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useStore } from "../store";
import jwt_decode from "jwt-decode";
const verifyJWT = (token) => {
  if (token === "") return false;
  var isExpired = false;
  var decodedToken = jwt_decode(token);
  var dateNow = new Date();
  let ts = Math.floor(dateNow / 1000);
  console.log('jwt',JSON.stringify(decodedToken,null,4));
  console.log('now',ts);
  if (decodedToken.exp < ts) isExpired = true;
  return !isExpired;
};

const AppRoute = ({ component: Component, path, isPrivate, ...rest }) => {
  const { state, dispatch } = useStore();
  console.log("app route:", state);
  return (
    <Route exact 
      path={path} 
      render={(props) => {
        if (isPrivate) {
          if (verifyJWT(state.token)) {
            console.log('verify ok')
            return(<Component {...props} />);
          } else {
            console.log('verify fail')
            return(<Redirect to={{ pathname: "/signin" }} />);
          }
        } else {
          return(<Component {...props} />);
        }
      }}
      {...rest}
    />
  );
};

export default AppRoute;

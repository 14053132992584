import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Container,
  Paper,
  
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import { Icon } from "@iconify/react";
import roadVariant from "@iconify/icons-mdi/road-variant";
import recycleIcon from "@iconify/icons-mdi/recycle";
//<Icon icon={roadVariant} />
//<Icon icon={recycleIcon} />
const useStyles = makeStyles((theme) => ({
  root: {
    flex: "1 0 auto",
  },
  paper: {
    margin: 20,
    padding: 20,
  },
  typo: {
    padding: 10,
    margin: 10,
  },
}));
function AboutUs_zh_TW() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Paper className={classes.paper}>
          <Typography variant="h3" className={classes.typo}>
            超象科技股份有限公司
          </Typography>
          <Typography className={classes.typo}>
            超象科技創立於2013年，總部設於台灣，擁有專業的研發團隊，主要是超音波醫療影像系統的開發商。
            <br/><br/>
            在總經理劉國平先生帶領下，我們以提升人類生活品質為使命，創新科技提供更人性化的行動醫療產品與服務。
            <br/><br/>
            目前專注於超音波醫療影像系統微型化，方便攜帶和移動，能帶給偏郷地區和落後第三世界更多醫療資源。
          </Typography>
          <Typography variant="h3" className={classes.typo}>
            經營理念
          </Typography>
          <Typography className={classes.typo}>
            超象致力於高項能超音波軟硬體開發，希望透過可以負擔的價格讓所有國家都可以享受高品質的醫療服務，縮短各地的醫療差距造福社會，守護每個家庭的健康。
          </Typography>
          <Typography className={classes.typo}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Card>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography>
                      <AccessTimeIcon style={{ fontSize: 40 }} />
                    </Typography>
                    <Typography variant="h6">誠實守信


</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography>
                      <DirectionsRunIcon style={{ fontSize: 40 }} />
                    </Typography>
                    <Typography variant="h6">主動進取</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography>
                      <Icon icon={roadVariant} style={{ fontSize: 40 }} />
                    </Typography>
                    <Typography variant="h6">穩健踏實</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Card>
                  <CardContent style={{ textAlign: "center" }}>
                    <Typography>
                      <Icon icon={recycleIcon} style={{ fontSize: 40 }} />
                    </Typography>
                    <Typography variant="h6">永續經營</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Typography>
          <Typography variant="h3" className={classes.typo}>
            LeSONO
          </Typography>

          <Typography className={classes.typo}>
            2013年，總經理劉國平先生帶著初創團隊勇敢踏上了醫材新創之路，以創新的產品概念，堅強的研發團隊及自有專利及技術，與過往夥伴選擇挑戰自我並創立自有品牌「LeSONO」。
            <br/><br/>
            近年因為老年人口快速增加，對國家的醫療支出造成嚴重衝擊，對於醫療設備的需求亦大幅增加，使得醫療資源供不應求，大型醫院經常人滿為患。我們認為未來預防醫學及居家照護的觀念將慢慢普及，而最能落實此觀念的醫療產品，便是微小化、可攜式的醫療儀器。
            <br/><br/>
            事實上，台灣有許多的大型醫療院所，在他們的急診室中，僅有一到兩台傳統超音波儀器，因為價錢高昂的原因，可能許久未更新，造成設備老舊；傳統超音波設備體積過於龐大，操作上不便、不及時，而通常都堆放在角落，不常使用。
            <br/><br/>
            超象科技秉持著提升人類生活品質的使命，致力於改善、解決這個現象，董事張小姐曾說：「希望未來，超象科技的產品可以為急診室盡到一份心力。」。我們希望開發出來的產品，能讓醫療流程更加順暢、快速，不再侷限於超音波儀器的體積、價格甚至距離。
            <br/><br/>
            超音波微型化技術可望使超音波有機會進入小型診所、救護車、居家照護、緊急醫療等，也能提供偏郷地區或第三世界更完善的醫療品質。
          </Typography>
          <Typography variant="h3" className={classes.typo}>
          認證
          </Typography>
          <Typography className={classes.typo}>
          超象科技為追求品質及符合世界法規標準，陸續取得各國認證，提供全球市場優質的超音波產品。
          </Typography>
          <Typography className={classes.typo}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Card>
                  <CardContent style={{ textAlign: "center" }}>
                    <img src="/ISO13485-212x300.jpg" />
                    <Typography variant="h6">ISO13485</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card>
                  <CardContent style={{ textAlign: "center" }}>
                    <img src="TFDA-212x300.jpg" />
                    <Typography variant="h6">TFDA</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card>
                  <CardContent style={{ textAlign: "center" }}>
                    <img src="FDA-232x300.jpg" />
                    <Typography variant="h6">FDA</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Typography>
        </Paper>
      </Container>
    </div>
  );
  
}

export default AboutUs_zh_TW;

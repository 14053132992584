import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { AppBar, Typography, Link, Container, Toolbar, Button, Box, Grid } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { useStore } from '../store';
import {languageOptions} from '../languages';
const useStyles = makeStyles(theme => ({
    root0: {
        flexGrow: 1,
    },
    toolBar: {
        'padding-left': 0,
        'padding-right': 0,
    },
    appBar: {

        background: theme.palette.grey[200],
    },

    title: {
        flexGrow: 1,
        marginLeft: 10,
    },
    footer: {
        margin: 4,
        fontSize: 17,
        alignItems: 'center',
        width: '100%'

    },
    contact: {
        textAlign: "right",
        color: theme.palette.text.primary
    },
    centerbar: {
        alignItems: 'center',
        background: theme.palette.grey[200],

    }
}));
function Copyright() {
    const { state, dispatch } = useStore();
    console.log('footer copyright', JSON.stringify(state, null, 4));
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '} {new Date().getFullYear()}, {' '}
            <Link color="inherit" href="https://leltek.com/">
                {state.dict["companyName"]}
            </Link>{' '}
        All Rights Reserved.

        </Typography>
    );
}
function FooterRWD() {
    const classes = useStyles();
    let defLang = localStorage.getItem("info") ? localStorage.getItem("info").lang : navigator.languages[0].replace('-', '_');
    //const [lang, setLang] = useState(defLang);
    const { state, dispatch } = useStore();
    console.log('footer state', JSON.stringify(state, null, 4))
    //console.log('def:lang',defLang,lang);
    const handleChange = (event) => {
        console.log('opt', event.target.value);
        //setLang(event.target.value);
        //localStorage.setItem('lang', event.target.value);
        //console.log('new',lang);
        dispatch({ type: 'CHANGE_LANG', lang: event.target.value })
    };
    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar}>
                <Container maxWidth="md">
                    <Toolbar className={classes.toolBar}>
                        <Grid container spacing={2}>
                            <Grid item sm={6} xs={12}>
                                <Box display='flex' flexGrow={1}>
                                    <div>
                                        <Link href="/">
                                            <img src="/iconLeltek3.png" alt="logo" style={{ maxHeight: 32 }} />
                                        </Link>
                                    </div>
                                    <Box flexWrap="nowrap" >
                                        <Button color="inherit"><Link style={{ textDecoration: 'none' }} href="/">{state.dict.footer.home}</Link></Button>
                                        <Button color="inherit"><Link style={{ textDecoration: 'none' }} href="/about">{state.dict.footer.about}</Link></Button>
                                        <Button color="inherit"><Link style={{ textDecoration: 'none' }} href="/privacy">{state.dict.footer.privacy}</Link></Button>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item sm={6} xs={12}>

                                <Box style={{ textAlign: "right" }} flexWrap="nowrap">
                                    <Button style={{ minWidth: 40 }}><Link target="_blank" className={classes.contact} href="https://www.facebook.com/LELTEKInc"><FacebookIcon /></Link></Button>
                                    <Button style={{ minWidth: 40 }}><Link target="_blank" className={classes.contact} href="http://linkedin.com/company/leltek"><LinkedInIcon /></Link></Button>
                                    <Button style={{ minWidth: 40 }}><Link target="_blank" className={classes.contact} href="https://twitter.com/LELTEKInc"><TwitterIcon /></Link></Button>
                                    <Button style={{ minWidth: 40 }}><Link target="_blank" className={classes.contact} href="https://www.youtube.com/channel/UCSfL8i7VUHRwnBBryn2sh3A"><YouTubeIcon /></Link></Button>

                                    <Button variant="outlined" color="primary" className={classes.contact}>
                                        <Link style={{ textDecoration: 'none' }} href="/contact">{state.dict.footer.contact}</Link>
                                    </Button>

                                   
                                </Box>


                            </Grid>
                        </Grid>
                    </Toolbar>
                </Container>


                <Container maxWidth="md">
                    <Toolbar className={classes.toolBar}>
                        <Typography variant='h6' className={classes.footer}>
                            <Copyright />
                        </Typography>
                    </Toolbar>
                </Container>

            </AppBar>
        </div >
    )
}
export default FooterRWD;
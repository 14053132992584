import React from "react";
import { useState } from 'react';
import { useStore } from '../store';
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Container,
  Paper,
  Link,
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";

import HomeIcon from '@material-ui/icons/Home';
import CallIcon from '@material-ui/icons/Call';
import MailIcon from '@material-ui/icons/Mail';

import ContactUs_en from './ContactUs-en';
import ContactUs_zh_TW from './ContactUs-zh_TW';
import dictionaryList from '../languages';

//<Icon icon={roadVariant} />
//<Icon icon={recycleIcon} />
const useStyles = makeStyles((theme) => ({
  root: {
    flex: "1 0 auto",
  },
  paper: {
    margin: 20,
    padding: 20,

  },
  typo: {
    padding: 10,
    margin: 10,
  },
  paper0: {
    display: "flex"
  },
  card0: {
    display: "flex",

    justifyContent: "space-between",

    flexDirection: "column",
    minWidth: "100%",
    marginTop: 8,
    marginBottom: 8,
    padding: 8,
  }
}));
const iframe = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3616.957893548757!2d121.53896951486325!3d24.967547184003504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3468018b37902be7%3A0xa8db933abfb8b89a!2sNo.%20293%2C%20Section%201%2C%20Beixin%20Road%2C%20Xindian%20District%2C%20New%20Taipei%20City%2C%20231!5e0!3m2!1sen!2stw!4v1578898598325!5m2!1sen!2stw" height="450" width="100%" frameborder="0" style="border:0;" allowfullscreen=""></iframe>	';
function Iframe(props) {
  return (<div style={{ minWidth: '100%' }} dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }} />);
}
function ContactUs() {
  const classes = useStyles();
  const { state, dispatch } = useStore();
  let lang = (state.lang) ? state.lang : 'en';
  //const ContactComp=`ContactUs${lang}`;
  //return <ContactComp/>;
  switch (lang) {
    case 'en':
      return <ContactUs_en />;
    case 'zh_TW':
      return <ContactUs_zh_TW />;
    default:
      return <ContactUs_en />;

  }
}

export default ContactUs;

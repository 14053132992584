import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Paper, Typography } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import http from '../http';
import { useStore } from '../store';
import Moment from 'react-moment';
import 'moment-timezone';
const Loader = require('react-loader');
const useStyles = makeStyles((theme) => ({
  root: {
    flex: "1 0 auto",
  },
  paper: {
    margin: 20,
    padding: 20,
  },
  typo: {
    padding: 10,
    margin: 10,
  },


}));


const rows0 = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];
function Exams(props) {
  const classes = useStyles();
  const { state, dispatch } = useStore();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let columns = [
    {
      name: "MRN",
      label: state.dict.exams.list.patient_id,
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "firstName",
      label: "First Name",
      options: { display: false }
    },
    {
      name: "lastName",
      label: "Last Name",
      options: { display: false }
    },
    {
      name: "Full Name",
      label: state.dict.exams.list.patient_name,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log(tableMeta.rowData, '......');
          return (
            <div>{tableMeta.rowData[1]} {tableMeta.rowData[2]}</div>
          );
        }
      }
    }, {
      name: "birthday",
      label: state.dict.exams.list.patient_bday,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log(JSON.stringify(tableMeta,null,4));
          let a = /(\d{4})(\d{2})(\d{2})/;
          if (tableMeta.rowData[4]) {
            let b = a.exec(tableMeta.rowData[4]);
            console.log('reg', tableMeta.rowData[4]);
            return (
              <Typography component={'span'} noWrap={true}>
                {b[1] + '-' + b[2] + '-' + b[3]}
              </Typography>

            );
          }
          else {
            return (<div>-</div>)
          }
        }
      }
    }, {
      name: "sex",
      label: state.dict.exams.list.patient_gender,
      options: {
        filter: true,
        sort: true,
      }
    },
    //6
    {
      name: "date",
      label: "date",
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    //7
    {
      name: "time",
      label: "time",
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    //8
    {
      name: "Exam Date",
      label: state.dict.exams.list.exam_date,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log(JSON.stringify(tableMeta,null,4));
          let a = /(\d{2})\/(\d{2})\/(\d{4})/;
          if (tableMeta.rowData[6] && tableMeta.rowData[7]) {
            let b = a.exec(tableMeta.rowData[6].split(',')[0]);
            console.log('reg', tableMeta.rowData[6]);
            let examdate = b[3] + '-' + b[1] + '-' + b[2] + ' ' + tableMeta.rowData[7].split(',')[0];
            return (
              <Typography component={'span'} noWrap={true}>
                {examdate}
              </Typography>

            );
          }
          else {
            return (<div>-</div>)
          }
        }
      }
    }, {
      // wrong wrong wrong
      name: "createdAt",
      label: state.dict.exams.list.upload_date,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //console.log(JSON.stringify(tableMeta,null,4));
          if (tableMeta.rowData[9]) {
            let dateLocal = new Date(tableMeta.rowData[9]).toLocaleString('en-US', { timezone: 'Asia/Taipei' });
            console.log('local', dateLocal);
            return (
              <Typography component={'span'} noWrap={true}>
                <Moment interval={0} format="YYYY-MM-DD HH:mm:ss">{dateLocal}</Moment>
              </Typography>

            );
          }
          else {
            return (<div>-</div>)
          }
        }
      }
    },
    {//10
      name: "imagePath",
      label: "time",
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    {
      name: "Images",
      label: state.dict.exams.list.images,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          let imagec = (tableMeta.rowData[10]) ? tableMeta.rowData[10].split(',').length : 0;
          return (<div>{imagec}</div>)
        }
      }
    },
    {
      name: "performedBy",
      label: state.dict.exams.list.clinician,
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: "ssid",
      label: state.dict.exams.list.scanner_id,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          //let imagec=tableMeta.rowData[10].split(',').length
          let ssid = (tableMeta.rowData[13]) ? tableMeta.rowData[13].split(',')[0] : '-';
          return (<div>{ssid}</div>)
        }
      }
    },
    {
      name: "_id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
        display: false
      }
    }
  ];

  useEffect(() => {
    let headers = { 'headers': { 'x-access-token': state.token } }
    http.get('/exam/exams', headers)
      .then(res => {
        console.log('res:', res.data.rows);
        //rows=res.data;
        //localStorage.setItem('info', JSON.stringify(res.data, null, 4));
        //let rows2 = res.data.map(p => { return { ...p, id: p._id } }

        //);
        //console.log('state:', rows);
        setRows(res.data.rows);
        setIsLoading(false);

        //setJwt(data.accessToken);
        //props.history.push('/exams');
      }
      )
      .catch(error => { console.log('error', error); })
      .finally(() => { console.log('state:', state); });
  }, []);
  const options = {
    filter: false,
    search: false,
    print: false,
    download: false,
    viewColumns: false,
    customToolbar: null,
    responsive: 'vertical',
    textLabels: {
      body: {
        noMatch: isLoading ?
          <Loader /> :
          state.dict.info.nodata,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: state.dict.info.rowsperpage,
        displayRows: state.dict.info.of,
      },
    },

    selectableRows: false,
    onRowClick: rowData => { console.log('row', rowData); props.history.push('/single/' + rowData[14]); }
  };




  //console.log(location.pathname);
  //console.log(watch("email"));
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Paper className={classes.paper}>
          <Typography variant="h3" className={classes.typo}>
            {state.dict.exams.title}
          </Typography>



          <MUIDataTable

            data={rows}
            columns={columns}
            options={options}
          />

        </Paper>
      </Container>
    </div>
  );
}

export default Exams;
